<template>
	<div class="lf_wai">


		<div class="lf_Btn_1">
			
			<!-- 登录 -->
			<div class="lf_login_page" v-if="showLogoButton">
				<div class="lf_login_inner_box">
					<img class="lf_close_img" @click="loginOut" src="../assets/images/close.png" alt="">
					<div class="lf_login_inner_tit"><img src="../assets/images/u24090.png" alt="">{{langs['login']}}</div>
					<div class="lf_login_inner_input_box">
						{{langs.Username}}
						<input v-model="logo.uname" :placeholder="langs.Inputyourusername" type="text">
					</div>
					<div class="lf_login_inner_input_box">
						{{langs.Password}}
						<input v-model="logo.pass" :placeholder="langs.Inputyourpassword" type="password">
					</div>
					<div class="lf_login_btn_B" @click="loginD">{{langs['login']}}</div>
					<div class="lf_resst_password" @click="forgetpassword">{{langs.resstpassword}}</div>
				</div>
			</div>
			<!-- 直播二维码 -->
			<div class="lf_login_page" style="z-index: 1000;" v-if="zbflag">

				<div class="lf_live_code_inner_box">
					<img class="lf_close_img" @click="zbx" src="../assets/images/close.png" alt="">
					<div class="lf_clpga_div_code">
						<img class="lf_code_img" src="../assets/images/u5273.svg" alt="">
					</div>
					<div class="lf_clpga_div">前往 <text>CLPGA视频号</text> 或 <text>CLPGA+小程序</text> 观看直播</div>
				</div>
			</div>
			<!-- 网站建设中 -->
		<div class="lf_jsz"  @click="Upgrading" style="z-index: 1000;" v-if="false&&jsflag&&undergoing=='Home'">
			<img class="lf_jsz_close_img" @click="zbx" src="../assets/images/close.png" alt="">
			<div class="lf_jsz_clpga_div" style="margin-top: 0;" v-if="systemlang=='cn'">新版网站试运行中！</div>
			<div class="lf_jsz_clpga_div" style="margin-top: 0;" v-if="systemlang=='en'">The new website  is now available for trial！</div>
		</div>
			<el-row style="box-shadow: 0px 5px 5px rgb(215 215 215 / 50%);">
				<el-col :xs="2" :sm="24" :md="24" style="display: none;">
					<div class="lf_phone_btn_box">
						<div class="lf_phone_btn_img" @click="btnFlagF"><img
								:style="{transform: (btnFlag==false?'rotate(0deg)':'rotate(180deg)')}"
								src="../assets/images/show.png" alt=""></div>
						<div v-if="btnFlag" @click="btnFlagF"
							style="width: 100%;position: absolute;left: 0;top: 100%;z-index: 999;"
							:style="{height:btnHeight}">
							<div class="lf_phone_btn_box_list" v-if="btnFlag" :style="{height:btnHeight}">
								<router-link :to="{path:'/',query:{lang:systemlang}}">
									<div :class="cd=='home'?'lf_phone_btn_box_list_select':''">
										<text>{{langs.Home}}</text></div>
								</router-link>
								<router-link :to="{path:'/MatchList',query:{lang:systemlang}}">
									<div :class="cd=='sc'?'lf_phone_btn_box_list_select':''">
										<text>{{langs.TournamentsSchedule}}</text></div>
								</router-link>
								<router-link :to="{path:'/news',query:{lang:systemlang}}">
									<div :class="cd=='xw'?'lf_phone_btn_box_list_select':''"><text>{{langs.News}}</text>
									</div>
								</router-link>
								<div @click="zb"><text>{{langs.Live}}</text></div>
								<router-link :to="{path:'/TecStatistics',query:{lang:systemlang}}">
									<div :class="cd=='js'?'lf_phone_btn_box_list_select':''">
										<text>{{langs.Stats}}</text></div>
								</router-link>
								<router-link :to="{path:'/Rank',query:{lang:systemlang}}">
									<div :class="cd=='ph'?'lf_phone_btn_box_list_select':''">
										<text>{{langs.PointsRankings}}</text></div>
								</router-link>
								<img src="../assets/images/menu.png" alt="">
							</div>
						</div>
					</div>
				</el-col>
				<el-col :xs="24" :sm="5" :md="5">
					<router-link :to="{path:'/',query:{lang:systemlang}}">
						<div class="lf_right_1_logo"><img src="../assets/images/logo_clpga.png" alt=""></div>
					</router-link>
				</el-col>
				<el-col :xs="24" :sm="19" :md="19" class="lf_margin_top lf_margin_top_m">
					<div class="lf_login_box">
						<a href="http://www.clpgq.com" target="_blank">
							<img class="lf_login_img" src="../assets/images/u58.png" alt="">
						</a>
						<div class="lf_login_box_r">
							<div class="lf_login_btn" v-if="userName!='' && showhide"><router-link
									:to="{path:'/Lists',query:{lang:systemlang}}">{{userName}} </router-link></div>
							<div class="lf_login_btn" v-if="userName=='' && showhide" @click="loginIn">{{langs['login']}}</div>
							<div v-if="showhide" v-on:click="changeLang(systemlang=='cn'?'en':'cn')" class="lf_login_changeL">
								<!-- <img v-if="systemlang=='cn'" src="../assets/images/EN.png" alt=""> -->
								<!-- <img v-if="systemlang=='en'" src="../assets/images/CN.png" alt=""> -->
								<view v-if="systemlang=='cn'">English</view>
								<view v-if="systemlang=='en'">中文</view>
							</div>
							<div class="lf_pc" v-if="metaNameFlag" @click="SPc">
								{{metaName=='width=device-width,initial-scale=1'?'PC':'Mobile'}}
							</div>
						</div>
					</div>
				</el-col>
				<el-col :xs="24" :sm="19" :md="19" class="lf_margin_top lf_margin_top_m_s">
					<div class="lf_web_btn_box" v-if="btnshow">
						<div v-if="cj.info&&WebModel" :class="cd=='cj'?'lf_phone_btn_box_list_select':''">
							<router-link :to="{path:'/Matchnow',query:{id:cj.info.ID,type:'cj',bFlag:'zdcj',lang:systemlang}}">
								{{langs.Score}}
								<span v-if="zbflag1">
									LIVE
								</span>
						<!-- 		v-if="zb.vdr_vod_url" -->
							</router-link>
						</div>
						<div v-else-if="cj.info&&!WebModel" :class="cd=='cj'?'lf_phone_btn_box_list_select':''">
							<router-link :to="{path:'/Matchnow',query:{id:cj.info.ID,type:'cj',bFlag:'lxb',lang:systemlang}}">
								{{langs.Score}}
								<span v-if="zbflag1">
									LIVE
								</span>
						<!-- 		v-if="zb.vdr_vod_url" -->
							</router-link>
						</div>
						<div :class="cd=='sc'?'lf_phone_btn_box_list_select':''"><router-link
								:to="{path:'/MatchList',query:{lang:systemlang}}">{{langs.TournamentsSchedule}}</router-link>
						</div>
						<div :class="cd=='xw'?'lf_phone_btn_box_list_select':''" @click="reload">{{langs.News}}</div>
					
						<div :class="cd=='ph'?'lf_phone_btn_box_list_select':''"><router-link
								:to="{path:'/player',query:{lang:systemlang}}"><text>{{langs["Players & Stats"]}}</text></router-link>
						</div>
					</div>
				</el-col>
			</el-row>
			
			<div class="lf_login_box lf_margin_top_w">
				<a href="http://www.clpgq.com" target="_blank">
					<img class="lf_login_img" src="../assets/images/u58.png" alt="">
				</a>
				<div class="lf_login_box_r">
					<div class="lf_login_btn" v-if="userName!='' && showhide"><router-link
							:to="{path:'/Lists',query:{lang:systemlang}}">{{userName}} </router-link></div>
					<div class="lf_login_btn" v-if="userName=='' && showhide" @click="loginIn">{{langs['login']}}</div>
					<div v-if="showhide" v-on:click="changeLang(systemlang=='cn'?'en':'cn')" class="lf_login_changeL">
						<!-- <img v-if="systemlang=='cn'" src="../assets/images/EN.png" alt=""> -->
						<!-- <img v-if="systemlang=='en'" src="../assets/images/CN.png" alt=""> -->
						<view v-if="systemlang=='cn'">English</view>
						<view v-if="systemlang=='en'">中文</view>
					</div>
					<div class="lf_pc" v-if="metaNameFlag" @click="SPc">
						{{metaName=='width=device-width,initial-scale=1'?'PC':'Mobile'}}
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- <div v-if="cd!='home'" class="lf_btn2_box"></div> -->
</template>

<script>
	import axiosx from '../axios/postx';
	import axios from '../axios/request';
	import {
		getSystemLang,
		getLangAll
	} from "../lang";
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				btnHeight: 1000,
				btnFlag: false,
				showLogoButton: false,
				zbflag: false,
				zbflag1:false,
				logo: {
					uname: '',
					pass: ''
				},
				userName: '',
				showhide: false,
				jsflag: true,
				cj: [],
				metaName: 'width=device-width,initial-scale=1',
				metaNameFlag: 0,
				zb:{},
				matchInfo:[],
				btnshow: false,
				WebModel:true
			}
		},
		name: 'Btn',
		props: {
			msg: String,
			flag: String,
			cd: String, //菜单
			undergoing: String,
		},
		watch:{
			
		},
		created() {

			var deviceWidth = document.documentElement.clientWidth || window.innerWidth
				if (deviceWidth >= 768) {
					this.WebModel = true;
				} else {
					this.WebModel = false;
				}

			this.btnHeight = document.documentElement.clientHeight + 'px';
			console.log(this.btnHeight);
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			//获取及时成绩
			//获取banner
			axios('matchs/match/curmatch.json')
			.then((res) => {
				let data = res.data.data;
				this.cj = data;
				this.btnshow = true;
			});
			this.setzb()
			var param = this.$route.query;
			if(param.id){
				axios('matchs/match/matchinfo.json', {
					id: param.id
				})
				.then((res) => {
					let data = res.data.data;
					this.matchInfo = data;
					console.log("this.matchInfo",this.matchInfo)
				})
			}
			if(param.mt_id){
				axios('matchs/match/matchinfo.json', {
					id: param.mt_id
				})
				.then((res) => {
					let data = res.data.data;
					this.matchInfo = data;
					console.log("this.matchInfo",this.matchInfo)
				})
			}
		},
		mounted() {
			//获取banner
			axiosx('user/userinfo.json?rand=' + Math.random())
				.then((res) => {
					let data = res.data;
					console.log(data);
					if (data.msgCode == '200') {
						this.userName = data.msg;
					}

					this.showhide = true;
				});
			document.body.style.overflow = "visible";
			// var url = window.location.href;             //获取当前url
			// var cs = url.split('?')[1];                //获取?之后的参数字符串
			// var cs_arr = cs.split('&');                    //参数字符串分割为数组
			// var cs={};
			// for(var i=0;i<cs_arr.length;i++){         //遍历数组，拿到json对象
			// 	cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
			// }
			// if(cs.lang!=localStorage.getItem('lang')){
			// 	localStorage.setItem('lang',cs.lang);
			// 	this.changeLang(cs.lang);
			// }
			var deviceWidth = document.documentElement.clientWidth || window.innerWidth;
			if (deviceWidth <= 768) {
				this.metaNameFlag = 1;
				this.showPC();
			}
		},
		methods: {
			setzb(){
				axios('matchs/match/zb.json')
				.then((res)=>{
					let data = res.data;
					if(data.msgCode == '200')
					{
						// this.$set(this.zb,"vdr_vod_url",data.data.vdr_vod_url);
						this.zb = data.data;
						if(this.zb.vdr_vod_url.length > 0)
						{
							this.zbflag1 = true;
						}else{
							this.zbflag1 = false;
						}
						console.log("zb",this.zb.vdr_vod_url)
					}
				});
			},
			btnFlagF() {
				if (this.btnFlag == false) {
					document.body.style.overflow = "hidden";
					this.btnFlag = true
				} else {
					document.body.style.overflow = "visible";
					this.btnFlag = false
				}

			},
			changeLang(a) {
				// console.log(a);return false;
				this.systemlang = a;
				this.langs = getLangAll(a);
				localStorage.setItem('lang', a);
				var langarr = {"1":"cn","2":"en"};
				var params = this.$route.query;
				params.lang = a;
				// console.log(this.matchInfo.matchlist);
				if(this.matchInfo&&this.matchInfo.matchlist&&this.matchInfo.matchlist.length>0){
					for(var k in this.matchInfo.matchlist){
						if(langarr[this.matchInfo.matchlist[k].ColumnLang] == a){
							if(params.id)
							{
								params.id = this.matchInfo.matchlist[k].id;
							}
							else if(params.mt_id)
							{
								params.mt_id = this.matchInfo.matchlist[k].id;
							}
							
							break;
						}
					}
				}
				console.log(params);
				let routeUrl = this.$router.resolve({
					query: params
				});
				window.open(routeUrl.href, '_self');
				// window.location.reload();
			},
			loginD() {
				//获取banner
				axiosx('user/login.json', this.logo)
					.then((res) => {
						let data = res.data;
						if (data.msgCode != '200') {
							alert(data.msg);
						} else {
							let routeUrl = this.$router.resolve({
								path: "/Lists",
								query: {
									lang: this.systemlang
								}
							});
							console.log(routeUrl.href);
							window.open(routeUrl.href, '_self');
						}
					});
			},
			gouOutD() {
				//获取banner
				axiosx('user/loginout.json?rand=' + Math.random())
					.then((res) => {
						let data = res.data;
						if (data.msgCode != '200') {
							alert(data.msg);
						} else {
							alert('退出成功');
							let routeUrl = this.$router.resolve({
								path: "/",
								query: {
									lang: this.systemlang
								}
							});
							this.userName = '';
							// console.log(routeUrl.href);
							window.open(routeUrl.href, '_self');
						}
					});
			},
			loginIn() {
				this.showLogoButton = true;
			},
			loginOut() {
				this.showLogoButton = false;
			},
			zb() {
				if (this.cj.info && this.cj.info.ID) {
					let routeUrl = this.$router.resolve({
						path: "/Match",
						query: {
							id: this.cj.info.ID,
							lang: this.systemlang
						}
					});
					console.log(routeUrl.href);
					window.open(routeUrl.href, '_self');
					window.location.reload();
				} else {

					this.zbflag = true;
				}
			},
			zbx() {
				this.zbflag = false;
			},
			Upgrading() {
				this.jsflag = false;
			},
			forgetpassword() {
				var uname = this.logo.uname;
				if (uname.trim() == "") {
					alert(this.langs.enteraccount);
				}
				axios("user/getemailbyuname", {
						"uname": uname
					})
					.then((res) => {
						var email = res.data.data.data.email
						if (email == "") {
							alert(this.langs.ts4);
						} else {
							if (confirm(this.langs.ts6 + email + this.langs.ts7)) {
								axios("user/sendresetemail", {
										"email": email,
										"user": uname
									})
									.then((result) => {
										console.log(result);
										var response = result.data.data.data
										if (response == 1) {
											alert(this.langs.ts2 + email + this.langs.ts3);
										} else if (response == 2) {
											alert(this.langs.ts4);
										} else {
											alert(this.langs.ts5);
										}
									})
							}
						}
					})
			},
			reload(){
				var path = this.$route.path;
				console.log("path",path)
				if(path == "/news"){
					location.reload()
				}else{
					let routeUrl = this.$router.resolve({
						path: "/news",
						query: {
							lang: this.systemlang
						}
					});
					window.open(routeUrl.href, '_self');
				}
			},
			SPc() {
				this.metaNameFlag = 1;
				var meta = document.createElement('meta');
				if (sessionStorage.getItem('metaName') == 'width=device-width,initial-scale=0.1') {
					sessionStorage.setItem('metaName', 'width=device-width,initial-scale=1');
					this.metaName='width=device-width,initial-scale=1'
					meta.content = sessionStorage.getItem('metaName')
				} else{
					sessionStorage.setItem('metaName', 'width=device-width,initial-scale=0.1');
					this.metaName='width=device-width,initial-scale=0.1'
					meta.content = sessionStorage.getItem('metaName')
				}
				meta.name = "viewport";
				document.getElementsByTagName('head')[0].appendChild(meta);
			},
			showPC(){
				var meta = document.createElement('meta');
				if (sessionStorage.getItem('metaName') == 'width=device-width,initial-scale=0.1') {
					this.metaName=sessionStorage.getItem('metaName')
					meta.content = sessionStorage.getItem('metaName')
					meta.name = "viewport";
					document.getElementsByTagName('head')[0].appendChild(meta);
				} else if(sessionStorage.getItem('metaName') == 'width=device-width,initial-scale=1'){
					this.metaName=sessionStorage.getItem('metaName')
					meta.content = sessionStorage.getItem('metaName')
					meta.name = "viewport";
					document.getElementsByTagName('head')[0].appendChild(meta);
				}
			}
		}
	}
</script>
<style>
	html {
		-webkit-filter: grayscale(100%);
		filter: progid:DXImageTransform.Microsoft.BasicImage(graysale=1);
	}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.lf_wai{
		width: 100%;
		position: relative;
		overflow: hidden;

	}
	.lf_Btn_1 {
		position: relative;
		width: 100%;
		
		margin: 0 auto;
	
	
	}

	.lf_right_1_logo {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 0.7rem;
	}

	.lf_right_1_logo img {
		width: 2.45rem;
		height: 0.7rem;
	}

	.lf_web_btn_box {
		height: 0.7rem;
		display: flex;
		align-items: center;
		
	}

	.lf_web_btn_box div {
		font-size: 0.26rem;
		color: #333;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		font-weight: bold;
		padding: 0 0.5rem;
		position: relative;

	}
	.lf_web_btn_box span{
		color: #C0153E;
		font-size: 0.18rem;
		position: absolute;
		top: 0;
		right: -0.04rem;
	}

	.lf_phone_btn_box {
		width: 0.7rem;
		height: 0.7rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_phone_btn_img {
		width: 0.5rem;
		height: 0.5rem;
	}

	.lf_phone_btn_box_list {
		position: absolute;
		top: 0;
		left: 0;
		width: 6rem;
		border-right: 5px solid #C0153E;
		z-index: 10;
		background-color: rgba(62, 58, 57, 0.9);
	}

	.lf_phone_btn_box_list div {
		width: 100%;
		height: 60px;
		border-bottom: 1px dashed #706D6D;
		display: flex;
		align-items: center;
		font-size: 0.3rem;
		color: #fff;
		letter-spacing: 0.05rem;
		line-height: 30px;
	}

	.lf_phone_btn_box_list div text {
		margin-left: 0.4rem;
	}

	.lf_phone_btn_box_list .lf_phone_btn_box_list_select {
		border-left: 3px solid #C0153E;
	}

	.lf_phone_btn_box_list .lf_phone_btn_box_list_select text {
		border-bottom: 3px solid #C0153E;
		margin-left: 0.4rem;
	}

	.lf_web_btn_box .lf_phone_btn_box_list_select {
		color: #C0153E;
		font-weight: bold;
	}

	.lf_web_btn_box div:hover {
		color: #C0153E;
		font-weight: bold;
	}

	.lf_phone_btn_box_list img {
		width: 4rem;
		left: 1rem;
		position: absolute;
		bottom: 50px;
	}

	.lf_login_box {
		float: right;
		z-index: 10;
		height: 0.5rem;
		background-color: rgba(255, 255, 255, 0.498039215686275);
		display: flex;
		align-items: center;
	}

	.lf_login_img {
		width: auto;
		height: 0.5rem;
		margin: 0 0.5rem;
	}

	.lf_login_btn {
		font-family: '微软雅黑', sans-serif;
		font-weight: 400;
		font-style: normal;
		color: #C0153E;
		font-size: 0.24rem;
		margin-right: 0.5rem;
		cursor: pointer;
	}

	.lf_login_changeL {
		height: 0.3rem;
		font-family: '微软雅黑', sans-serif;
		font-weight: 400;
		font-style: normal;
		color: #C0153E;
		font-size: 0.24rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		cursor: pointer;
		margin-right: 0.5rem;
	}

	.lf_login_changeL img {
		width: 100%;
		height: 100%;
	}

	.lf_login_page {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.498039215686275);
		z-index: 999;
		display: flex;
		align-items: center;
		justify-content: center;

	}

	.lf_login_inner_box {
		width: 5rem;
		height: 5rem;
		background-color: rgba(250, 250, 251, 1);
		border: none;
		border-radius: 5px;
		box-shadow: 5px 5px 50px rgb(55 55 55 / 31%);
		padding: 0.5rem;
		position: relative;
	}

	.lf_close_img {
		width: 0.3rem;
		height: 0.3rem;
		position: absolute;
		right: 0.1rem;
		top: 0.1rem;
		cursor: pointer;
	}

	.lf_login_inner_tit {
		font-family: '微软雅黑';
		font-weight: bold;
		font-style: normal;
		font-size: 0.28rem;
		letter-spacing: 2px;
		color: #C0153E;
		margin: 0 auto 0.1rem;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_login_inner_tit img{
		width: 0.36rem;
		height: 0.36rem;
		margin-right: 0.06rem;
	}
	.lf_login_inner_input_box {
		width: 100%;
		height: 0.8rem;
		background: inherit;
		background-color: rgba(255, 255, 255, 1);
		border: none;
		border-radius: 0.1rem;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-size: 0.24rem;
		margin-bottom: 0.26rem;
		margin-top: 0.26rem;
		display: flex;
		flex-direction: column;
		text-indent: 0.25rem;
		justify-content: space-around;
		color: #666;
	}

	.lf_login_inner_input_box input {
		margin-left: 0.25rem;
		font-size: 0.28rem;
		background-color: #fff!important;
		color: #333;
	}

	.lf_login_btn_B {
		width: 4rem;
		height: 0.52rem;
		background: inherit;
		background-color: rgba(182, 8, 29, 1);
		border: none;
		border-radius: 0.1rem;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.28rem;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 0.5rem;
		cursor: pointer;
	}

	.lf_resst_password {
		color: #666;
		text-align: right;
		margin-top: 0.24rem;
		font-size: 0.24rem;
		cursor: pointer;
	}

	.lf_live_code_inner_box {
		width: 9rem;
		height: 6rem;
		background-color: rgba(250, 250, 251, 1);
		border-radius: 5px;
		padding: 0.7rem;
		position: relative;
	}

	.lf_code_img {
		width: 3.5rem;
		height: 3.5rem;
		margin: 0 auto;
	}

	.lf_clpga_div_code {
		width: 4rem;
		height: 4rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
		background-color: #fff;
		margin: 0 auto;
	}

	.lf_clpga_div {
		margin-top: 0.3rem;
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
		font-style: normal;
		font-size: 0.24rem;
		font-weight: bold;
		text-align: center;
	}

	.lf_clpga_div text {
		color: #C0153E;
	}

	.lf_btn2_box {
		height: 0.5rem;
	}

	.lf_jsz {
		width: 100%;
		padding: 0.1rem;
		background-color: rgba(0, 0, 0, 0.498039215686275);
		color: #fff;
		font-size: 0.16rem;
		position: fixed;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_jsz_close_img {
		width: 0.2rem;
		height: 0.2rem;
		position: absolute;
		right: 0.2rem;
	}

	.lf_pc {
		height: 0.3rem;
		font-family: '微软雅黑', sans-serif;
		font-weight: 400;
		font-style: normal;
		color: #C0153E;
		font-size: 0.18rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		cursor: pointer;
		margin-left: 0.1rem;
	}
.lf_login_box_r{
	display: flex;
	align-items: center;
}
.lf_margin_top_m{
	display: none;
}

	@media screen and (max-width: 768px) {
		.lf_login_changeL {
			font-size: 0.24rem;
			width: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 0.2rem;
		}

		.lf_live_code_inner_box {
			width: 6rem;
		}

		.lf_resst_password {
			font-size: 0.26rem;
		}

		.lf_login_btn {
			font-size: 0.24rem;
			padding: 0 0.2rem;
			margin-right: 0.2rem;
		}

		.lf_pc {
			font-size: 0.24rem;
			color: #C0153E;
			display: unset;
			margin: 0 0.2rem;
			margin-right: 0.2rem;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.lf_margin_top {
		/* 	margin-top: 0.46rem; */
		}

		.lf_login_box {
			height: 0.7rem;
			width: 100%;
			background-color: #fff;
			display: flex;
			align-items: center;
			justify-content: space-between;
			box-shadow: 0 0px 0.05rem rgba(215, 215, 215, 0.498039215686275);
		}

		.lf_login_img {
			margin: 0 0.2rem;
		}

		.lf_web_btn_box {
			background-color: #fff;
			overflow-x: scroll;
			justify-content: space-around;
		}

		.lf_web_btn_box div {
			flex-shrink: 0;
			font-size: 0.24rem;
			padding: 0 0.35rem;
		}
		.lf_web_btn_box span{
			color: #C0153E;
			font-size: 0.18rem;
			position: absolute;
			top: 0;
			right: -0.2rem;
		}
		.lf_web_btn_box .lf_phone_btn_box_list_select a {
			color: #c0153e;
			font-weight: 700;
			padding: 0.05rem 0.1rem;
		}

		.lf_jsz {
			width: 100%;
			padding: 0.1rem;
			background-color: rgba(0, 0, 0, 0.498039215686275);
			color: #fff;
			font-size: 0.24rem;
			position: fixed;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.lf_jsz_close_img {
			width: 0.2rem;
			height: 0.2rem;
			position: absolute;
			right: 0.2rem;
		}

		.lf_login_inner_tit {
			font-size: 0.28rem;
			font-weight: bold;
		}

		.lf_login_inner_input_box {
			font-size: 0.26rem;
		}

		.lf_login_inner_input_box input {
			font-size: 0.26rem;
		}

		.lf_login_inner_input_box {
			height: 0.72rem;
		}

		.lf_login_btn_B {
			font-size: 0.26rem;
		}

		.lf_login_inner_box {
			height: 5.5rem;
		}
		.lf_wai{
			height: unset;
		}
		.lf_login_box_r{
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
		.lf_margin_top_m{
			display: flex;
		}
		.lf_margin_top_w{
			display: none;
		}
		.lf_right_1_logo{
			box-shadow: 0 0px .05rem rgba(215, 215, 215, 0.498039215686275);
		}
		.lf_margin_top_m_s{
			margin-bottom: 0.05rem;
			box-shadow: 0 0px .05rem rgba(215, 215, 215, 0.498039215686275);
		}
	}
</style>