<template>
	<!-- <navs></navs> -->
	<div class="banner_top" v-show="bannershow && bannershow['OTHER_TOP'] == '1'">
		<banner :type="0" abs="OTHER_TOP"></banner>
	</div>
	<Btn cd="xw"></Btn>
	<div class="select-back" v-if="typelistshow || yearsshow" @click="typelistshow=false;yearsshow=false;"></div>
	<div class="lf_footer1">

		<div class="lf_footer1_tit_box">
			<template v-if="mt_id">
				<div class="lf_foot_tit_text1">{{mt_cn_name}}</div>
				<div class="lf_foot_tit_line"></div>
				<div class="lf_foot_tit_text2">News</div>
			</template>
			<template v-else>
				<div class="lf_foot_tit_text1">{{langs.newslist_news}}</div>
				<div class="lf_foot_tit_line"></div>
				<div class="lf_foot_tit_text2" v-if="systemlang=='cn'">{{langs_en.newslist_news}}</div>
				<div class="lf_foot_tit_text2" v-if="systemlang=='en'">{{langs_cn.newslist_news}}</div>
			</template>
			<div class="search_pos">
				<div class="search_Div" @click.stop="">
					<div class="searchbox" :class="inputShow==false ? 'out':'showInto'">
						<input :placeholder="langs.PleaseInput1" v-model="searchstring" type="text" />
						<img @click="searchM()" v-if="searchstring!=''" style="width: 0.3rem;position: absolute;right: 1.6rem;top: 0.1rem;z-index: 9;" class="hand" src="../../assets/images/u21104.png" alt="" />
						<div class="searchbutton hand" @click="tonewmod">{{langs.psearch_search}}</div>
					</div>
				</div>
				<div class="searchimg hand" @click.stop="inputBtnShow">
					<img src="../../assets/images/u6324.png" />
				</div>
			</div>
		</div>

	</div>


	<div class="all_body" style="margin-top: 0.68rem;">
	
		<div class="leftbox">
			<div class="changebox">
				<div class="changekind">
					<div @click.stop="changetypelistshow">
						<div class="d_title hand">
							<blockquote v-if="systemlang=='cn'">{{typelist[type].cn_name}} |
								<span>{{typelist[type].en_name}}</span></blockquote>
							<blockquote v-if="systemlang=='en'">{{typelist[type].en_name}} |
								<span>{{typelist[type].cn_name}}</span></blockquote>
						</div>
						<img src="../../assets/images/u182.png" class="d_img" />
					</div>
					<div class="select-left into" v-if="typelistshow">
						<div class="select_title">
							<blockquote v-if="systemlang=='cn'">{{typelist[type].cn_name}} |
								<span>{{typelist[type].en_name}}</span></blockquote>
							<blockquote v-if="systemlang=='en'">{{typelist[type].en_name}} |
								<span>{{typelist[type].cn_name}}</span></blockquote>
						</div>
						<div class="select_socl">
							<div class="select_item hand" v-for="(item,index) in typelist"
								:class="type==item.id?'select_item_hover':''" @click="changetype(item.id)">
								<span v-if="systemlang=='cn'">{{item.cn_name}}</span>
								<span v-if="systemlang=='en'">{{item.en_name}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="changetime hand" @click.stop="changeyearsshow">
					<span class="timeword">
						{{year+langs.year_cn}}
					</span>
					<img src="../../assets/images/u2764.png" />
					<div class="select-right into" style="top: 0.6rem;" v-if="yearsshow">
						<div class="select_title">{{year+langs.year_cn}}</div>
						<div class="select_socl">
							<div class="select_item hand" v-for="(item,index) in years"
								:class="year==item.value?'select_item_hover':''" @click.stop="changeyear(item.value)">
								{{item.label}}
							</div>
						</div>
					</div>

				</div>
			</div>
			<div class="itembox lazyLoad" v-loading="loading">
				<div class="item" v-for="(b,index) in news6" v-if="news6.length>0">
					<template v-if="index<6">
						<router-link target="_blank" :to="{path:'/newsinfo',query:{id:b.ID?b.ID:0,lang:systemlang,category:type}}">
							<div class="itemimg" style="text-align:center;">
								<img v-if="b.ArticleImg" style="width:auto;height: 100%; margin:0 auto;" v-lazy="b.ArticleImg" alt="" />
								<img v-else src="../../assets/images/u121245.png" />
							</div>
							<div class="itemtitle">
								{{b.ArticleTitle}}
							</div>
							<div class="iteminfo">
								{{b.ArticleBrief}}
							</div>
							<div class="itemtime">
								{{b.ArticleTime}}
							</div>
						</router-link>
					</template>
				</div>
				<div v-else>
					<empty></empty>
				</div>
			</div>
			<!-- <div class="banner">
				<img src="../../assets/images/u59.png" />
			</div> -->
			<div class="banner" v-if="news6.length>0"  v-show="bannershow && bannershow['NEWS_CENTER'] == '1'">
				<banner @EventBack='setbannershow' heightNew="1.22rem" :type="2" abs="NEWS_CENTER"></banner>
			</div>
			<div class="newwai" v-if="news6&&news6.length>0">
				<NewListmore v-if="year" v-show="news6&&news6.length>0" ref="newslistmore" type="search" hide="1" id="search" :mt_id="mt_id"
					:category="type" :year="year" pl_name="" mt_name="" :front="front" :showempty="0" limit="12" starttime="1" @setPage="setPage" issort="1"></NewListmore>
			</div>
		
		</div>
		<div class="rightbox">
			<div class="lf_match_info_box" style="margin:0;">
				<div class="d_title">{{langs.newslist_saizhanxinwen}} | <span> {{langs.newslist_saizhanxinwen_en}}</span></div>
			</div>
			<div style="position: relative;overflow: hidden;">
				<div class="xuxian"></div>
				<div class="xuxianshang">
					<div class="headwordboxNew" :style="systemlang=='en'?'letter-spacing:0':''" v-if="curmatch&&curmatch.length>0">
						{{langs.newslist_jinxingzhongbisai}}
					</div>
					<div class="hand" :class="mt_id==item.ID?'competition':'competitionover'" v-for="item in curmatch" v-if="curmatch&&curmatch.length>0"
						@click="changemt_id(item.ID)" v-show="item.have_new==1">
						<div class="competitiontime " >
							<div class="lf_time_box_compet">
								<span class="span1">{{langs.newslist_yues[item.month]}}</span>
								<span class="span2">{{langs.newslist_yue}}</span>
							</div>
							<span class="span3">{{item.day}}</span>
						</div>
						<div class="competitiontitle">{{item.ColumnName}}</div>
						<div class="competitioninfo">{{item.ColumnPlace}}</div>
					</div>
					<div class="headwordboxNew" :style="systemlang=='en'?'letter-spacing:0':''" v-if="old&&old.length>0">
						{{langs.newslist_jieshubisai}}
					</div>
					<div class="hand" :class="mt_id==item.ID?'competition':'competitionover'" v-for="item in old" v-if="old&&old.length>0"
						@click="changemt_id(item.ID)" v-show="item.have_new==1">
						<div class="competitiontime" >
							<div class="lf_time_box_compet">
								<span class="span1">{{langs.newslist_yues[item.month]}}</span>
								<span class="span2">{{langs.newslist_yue}}</span>
							</div>
							<span class="span3">{{item.day}}</span>
						</div>
						<div class="competitiontitle">{{item.ColumnName}}</div>
						<div class="competitioninfo">{{item.ColumnPlace}}</div>
					</div>
					<div class="headwordboxNew" :style="systemlang=='en'?'letter-spacing:0':''" v-if="futrue&&futrue.length>0">
						{{langs.newslist_jijiangkaisai}}
					</div>
					<div class="hand" :class="mt_id==item.ID?'competition':'competitionover'" v-for="item in futrue" v-if="futrue&&futrue.length>0"
						@click="changemt_id(item.ID)" v-show="item.have_new==1">
						<div class="competitiontime" >
							<div class="lf_time_box_compet">
								<span class="span1">{{langs.newslist_yues[item.month]}}</span>
								<span class="span2">{{langs.newslist_yue}}</span>
							</div>
							<span class="span3">{{item.day}}</span>
						</div>
						<div class="competitiontitle">{{item.ColumnName}}</div>
						<div class="competitioninfo">{{item.ColumnPlace}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="rightboxs">
			<div class="lf_match_info_box" style="margin-top: 0.6rem;">
				<div class="d_title">{{langs.newslist_saizhanxinwen}} | <span> {{langs.newslist_saizhanxinwen_en}}</span></div>
			</div>
			<div style="position: relative;overflow: hidden;">
				<div class="xuxianshang">
					<div class="headwordboxNew" :style="systemlang=='en'?'letter-spacing:0':''" v-if="curmatch&&curmatch.length>0">
						{{langs.newslist_jinxingzhongbisai}}
					</div>

					<div class="competitionbox" v-if="curmatch&&curmatch.length>0">
						<div :class="mt_id==item.ID?'competition':'competitionover'" v-for="item in curmatch" @click="changemt_id(item.ID);" v-show="item.have_new==1">
							<div class="competitiontime" >
								<div class="lf_time_box_compet">
									<span class="span1">{{langs.newslist_yues[item.month]}}</span>
									<span class="span2">{{langs.newslist_yue}}</span>
								</div>
								<span class="span3">{{item.day}}</span>
							</div>
							<div class="competitiontitle">{{item.ColumnName}}</div>
							<div class="competitioninfo">{{item.ColumnPlace}}</div>
						</div>
					</div>

					<div class="headwordboxNew" :style="systemlang=='en'?'letter-spacing:0':''" v-if="old&&old.length>0">
						{{langs.newslist_jieshubisai}}
					</div>
					<div class="competitionbox" v-if="old&&old.length>0">
						<div :class="mt_id==item.ID?'competition':'competitionover'" v-for="item in old" v-if="old&&old.length>0"
							@click="changemt_id(item.ID);" v-show="item.have_new==1">
							<div class="competitiontime" >
								<div class="lf_time_box_compet">
									<span class="span1">{{langs.newslist_yues[item.month]}}</span>
									<span class="span2">{{langs.newslist_yue}}</span>
								</div>
								<span class="span3">{{item.day}}</span>
							</div>
							<div class="competitiontitle">{{item.ColumnName}}</div>
							<div class="competitioninfo">{{item.ColumnPlace}}</div>
						</div>
					</div>
					<div class="headwordboxNew" :style="systemlang=='en'?'letter-spacing:0':''" v-if="futrue&&futrue.length>0">
						{{langs.newslist_jijiangkaisai}}
					</div>
					<div class="competitionbox" v-if="futrue&&futrue.length>0">
						<div :class="mt_id==item.ID?'competition':'competitionover'" v-for="item in futrue" v-if="futrue&&futrue.length>0"
							@click="changemt_id(item.ID);" v-show="item.have_new==1">
							<div class="competitiontime" >
								<div class="lf_time_box_compet">
									<span class="span1">{{langs.newslist_yues[item.month]}}</span>
									<span class="span2">{{langs.newslist_yue}}</span>
								</div>
								<span class="span3">{{item.day}}</span>
							</div>
							<div class="competitiontitle">{{item.ColumnName}}</div>
							<div class="competitioninfo">{{item.ColumnPlace}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>



	<Footer></Footer>
	<Footer1></Footer1>
</template>

<script>
	// @ is an alias to /src
	import navs from "../../components/nav.vue";
	import Btn from "../../components/Btn.vue";
	import Footer from "../../components/Footer.vue";
	import Footer1 from "../../components/Footer1.vue";
	import axios from '../../axios/request';
	import NewListmore from "../../components/NewListmore.vue";
	import banner from "../../components/banner.vue";
	import empty from "../../components/empty.vue";
	import {
		getSystemLang,
		getLangAll
	} from "../../lang";
	//这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue/swiper-vue';

	import {
		Autoplay,
		Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
	//这里就是更新后的路径，以前是import 'swiper/css'

	var enddata = (new Date()).getFullYear();
	var years = [];
	for (var i = enddata; i >= 2004; i--) {
		years.push({
			value: i,
			label: i
		});
	}
	var today = new Date();     //创建日期对象
	var year = today.getFullYear();     //获取年份
	var month = today.getMonth()+1;    //获取月份
	var day = today.getDate();          //获取日期
	var hour= today.getHours();//得到小时数
	var minute= today.getMinutes();//得到分钟数
	var second= today.getSeconds();//得到秒数
	var yyyymmdd = year+"-"+month+"-"+day+" "+hour+":"+minute+":"+second;  //拼接成yyyymmdd形式字符串
	export default {
		data() {
			return {
				inputShow: false,
				langs: '',
				langs_cn: '',
				langs_en: '',
				systemlang: 'cn',
				years: years,
				year: '',
				matchs: [],
				mt_id: '',
				mt_cn_name: '',
				pl_name: '',
				banner: {},
				wonderful: {},
				xhs: {},
				match: {},
				type: "0",
				typelistshow: false,
				yearsshow: false,
				typelist: [],
				news6: [],
				old: [],
				futrue: [],
				curmatch: [],
				matchlist: [],
				searchstring: "",
				bannershow: [],
				front:"6",
				yearenddate:"",
				starttime:"",
				loading: false
			}
		},
		props: {

		},
		watch: {
			year(newVal, oldVal) {
				if (!this.typelist || !this.langs_cn || !this.langs_en) {
					return;
				}
				var curyear = (new Date()).getFullYear();
				if (newVal == curyear) {
					this.typelist[0].cn_name = this.langs_cn["newslist_zuixin"];
					this.typelist[0].en_name = this.langs_en["newslist_zuixin"];
				} else {
					this.typelist[0].cn_name = this.langs_cn["newslist_quanbu"];
					this.typelist[0].en_name = this.langs_en["newslist_quanbu"];
				}
			},
			typelistshow(newVal,oldVal){
				var width = document.body.clientWidth;
				if(width<=750 && newVal == true){
					document.body.style.overflow = 'hidden'
				}else{
					document.body.style.overflow = 'unset'
				}
			},
			yearsshow(newVal,oldVal){
				var width = document.body.clientWidth;
				if(width<=750 && newVal == true){
					document.body.style.overflow = 'hidden'
				}else{
					document.body.style.overflow = 'unset'
				}
			}
		},
		name: 'Home',
		components: {
			navs,
			Btn,
			Footer,
			Footer1,
			Swiper,
			SwiperSlide,
			NewListmore,
			banner,
			empty
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			this.langs_cn = getLangAll("cn");
			this.langs_en = getLangAll("en");
			this.typelist = [{
					id: 0,
					cn_name: this.langs_cn["newslist_zuixin"],
					en_name: this.langs_en["newslist_zuixin"]
				},
				{
					id: 1,
					cn_name: this.langs_cn["newslist_pinpai"],
					en_name: this.langs_en["newslist_pinpai"]
				},
				{
					id: 2,
					cn_name: this.langs_cn["newslist_qiuyuan"],
					en_name: this.langs_en["newslist_qiuyuan"]
				},
				{
					id: 3,
					cn_name: this.langs_cn["newslist_guoji"],
					en_name: this.langs_en["newslist_guoji"]
				},
				{
					id: 4,
					cn_name: this.langs_cn["newslist_zhuangbei"],
					en_name: this.langs_en["newslist_zhuangbei"]
				},
				{
					id: 5,
					cn_name: this.langs_cn["newslist_jiaoxue"],
					en_name: this.langs_en["newslist_jiaoxue"]
				}
			];
			var year = (new Date()).getFullYear();

			if(this.$route.query.year)
			{
				year = this.$route.query.year;
			}
			if(this.$route.query.category)
			{
				this.type = this.$route.query.category;
			}
			if(this.$route.query.mt_id)
			{
				this.mt_id = this.$route.query.mt_id;
			}

			this.year = year;
			// this.getData();
			
			document.body.addEventListener('click', (e) => {
				this.typelistshow = false;
				this.yearsshow = false;
				this.inputShow = false;
			});


			//判断本年度是否有新闻
			axios('news/search.json', {
					category: this.type,
					year: year,
					// starttime:this.starttime,
					limit: 1
				})
				.then((res) => {
					console.log(res.data.data.length,'ssssssssss');
					if(res.data.data.length <=0){
						this.year = year-1;
						console.log(year-1,'aaaaaaaaaaaaa')
						this.getmatchlist(year-1);
						this.getData();
					}
					else
					{
						this.getmatchlist(year);
						this.getData();
					}
					
				});
			
		},
		setup() {

		},
		methods: {
			inputBtnShow() {
				var width = document.body.clientWidth;
				if(width<=750){
					var params = {
						lang: getSystemLang()
					}
					let routeUrl = this.$router.resolve({
						path: "/newmod",
						query: params
					});
					// console.log(routeUrl.href);
					window.open(routeUrl.href, '_blank');
				}else{
					this.inputShow = !this.inputShow
				}
			},
			searchM: function() {
				this.searchstring = "";
			},
			setbannershow(abs, isshow) {
				this.bannershow[abs] = isshow;
			},
			changetype(type) {
				this.typelistshow = false;
				this.type = type;
				this.front = 6;
				this.getData();
			},
			changeyear(year) {
				this.yearsshow = false;
				this.year = year;
				this.type = "0"
				this.mt_id = "";
				this.mt_cn_name = "";
				this.mt_en_name = "";
				this.front = 6;
				this.getData();
				this.getmatchlist(year);
			},
			changetypelistshow() {
				this.typelistshow = !this.typelistshow;
				var width = document.body.clientWidth;
				if(this.typelistshow==true && width<=750){
					document.body.style.overflow = 'hidden'
				}else{
					document.body.style.overflow = 'unset'
				}
				this.yearsshow = false;
				this.$nextTick(() => {
					const parent = document.querySelector(`.select_item_hover`).offsetTop;
					document.querySelector(`.select_socl`).scrollTo(0, parent - 100)
				});
			},
			changeyearsshow() {
				this.typelistshow = false;
				this.yearsshow = !this.yearsshow;
				var width = document.body.clientWidth;
				if(this.yearsshow==true && width<=750){
					document.body.style.overflow = 'hidden'
				}else{
					document.body.style.overflow = 'unset'
				}
				this.$nextTick(() => {
					const parent = document.querySelector(`.select_item_hover`).offsetTop;
					document.querySelector(`.select_socl`).scrollTo(0, parent - 100)
				});
			},
			changemt_id(mt_id) {
				if (this.mt_id != mt_id) {
					this.mt_id = mt_id;
					this.starttime = "";
					this.front = 6;
				} else {
					this.mt_id = "";
					this.mt_cn_name = "";
					this.mt_en_name = "";
					this.front = 6;
					this.starttime = this.yearenddate;
				}
				if (this.mt_id != '') {
					for (var k in this.matchlist) {
						if (this.matchlist[k].ID&&this.matchlist[k].ID == this.mt_id) {
							this.mt_cn_name = this.matchlist[k].ColumnName;
							this.mt_en_name = this.matchlist[k].otherColumnName;
							break;
						}
					}
				}
				this.getData();
				var width = document.body.clientWidth;
				if(width<=750){
					window.scrollTo(0,0);
				}
			},
			getData() {
				this.loading = true
				var type = this.type;
				var year = this.year;
				var mt_id = this.mt_id;
				var newslistmore = this.$refs.newslistmore
				axios('news/search.json', {
					category: type,
					year: year,
					mt_id: mt_id,
					issort: 1,
					// starttime:this.starttime,
					limit: 6
				})
				.then((res) => {
					
					let data = res.data.data;
				
					console.log(11111111111,data)
					var news6 = [];
					for(var i=0;i<(data.length>6?6:data.length);i++){
						news6.push(data[i])
					}
					this.news6 = news6;
					this.loading = false
					newslistmore.getData(1);
				});
			},
			getmatchlist(year) {
				this.loading = true;
				this.futrue = [];
				this.old = [];
				this.curmatch = [];
				this.matchlist = [];
				let param = {};
				if (year) {
					param.year = year;
				} else {
					param.year = this.year;
				}
				param.limit = 'all';
				var yearenddate = "";
				param.noparam = '1'; //不需要查太多内容
				axios('matchs/match/list.json', param)
					.then((res) => {
						let data = res.data.data;
						let futrue = [];
						for (let i in data) {
							if (data[i].zt == -1&&data[i].have_new == 1) {
								this.old.push(data[i]);
							} else if (data[i].zt == 1&&data[i].have_new == 1) {
								futrue.push(data[i]);
							} else if (data[i].zt == 0&&data[i].have_new == 1) {
								this.curmatch.push(data[i]);
							}
							this.matchlist.push(data[i]);
						}
						for (var k in this.matchlist) {
						if (this.matchlist[k].ID&&this.matchlist[k].ID == this.mt_id) {
							this.mt_cn_name = this.matchlist[k].ColumnName;
							this.mt_en_name = this.matchlist[k].otherColumnName;
							break;
						}

						console.log("this.old",this.old)

						
					}
						
						this.yearenddate = yearenddate;
						futrue.reverse();
						for (let i in futrue) {
							this.futrue.push(futrue[i]);
						}
					});
			},
			tonewmod() {
				if (this.searchstring == "") {
					alert(this.systemlang == 'cn' ? '请输入内容' : "Please enter content");
					return;
				}
				var params = {
					lang: getSystemLang(),
					search: this.searchstring
				}
				let routeUrl = this.$router.resolve({
					path: "/newMod",
					query: params
				});
				window.open(routeUrl.href, '_blank');
			},
			setPage(page){
				this.front = page.front
			}
		}
	}
</script>


<style scoped="scoped">
	.span1{
		color: #fff;
		font-size: 0.3rem;
		line-height: 0.6rem;
	}
	.span2{
		color: #fff;
		font-size: 0.2rem;
	}
	.span3{
		color: #fff;
		font-size: 0.18rem;
	}
	/* .competition .span1{
		color: #C0153E;font-size: 0.3rem;line-height: 0.6rem;
	}
	.competition .span2{
		color: #C0153E;font-size: 0.2rem;
	}
	.competition .span3{
		color: #C0153E;font-size: 0.18rem;
	} */
	.leftbox {
		width: 15.2rem;
		float: left;
		overflow: hidden;
	}

	.search_pos {
		top: 0.2rem;
		position: absolute;

		overflow: hidden;
		height: 0.5rem;
		right: 0.3rem;

	}

	.search_Div {
		position: relative;
		width: 5.8rem;
		height: 0.5rem;
		overflow: hidden;
		float: left;
	}

	.search_pos .searchbox {
		position: absolute;


	}

	.search_pos .searchimg {

		float: right;
		margin-right: 0.06rem;
	}

	.showInto {
		right: 0.2rem;
		animation-name: intoNew;
		animation-duration: 0.5s;

	}

	.out {
		right: -6.4rem;
		animation-name: outNew;
		animation-duration: 0.5s;

	}

	@keyframes intoNew {
		0% {
			right: -6.4rem;
		}

		100% {
			right: 0.2rem;
		}
	}

	@keyframes outNew {
		0% {
			right: 0.2rem;
		}

		100% {
			right: -6.4rem;

		}
	}

	.rightbox {
		width: 3.1rem;
		float: right;
	}

	.rightboxs {
		display: none;
	}





	.changetime {
		width: 2.6rem;
		text-align: center;
		background-color: #C0153E;
		border-radius: 0.1rem;
		height: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 0.5rem;

		font-size: 0.28rem;
		color: #fff;
		font-weight: bold;
		float: right;
	}

	.changetime img {
		width: 0.3rem;
		height: 0.3rem;
		float: left;
		margin-top: 0;
		position: relative;
		top: -2px;
		margin-left: 0.1rem;
	}

	.timeword {
		float: left;
		margin-left: 0.5rem;
		font-size: 0.3rem;
	}



	.itembox {
		width: 15.86rem;
		overflow: hidden;
		margin-top: 0.2rem;
	}

	.item {
		width: 7.5rem;
		height: 5.6rem;
		float: left;
		background-color: #FAFAFA;
		margin-right: 0.2rem;
		margin-bottom: 0.2rem;
	}

	.itemimg {
		width: 7.5rem;
		height: 4.2rem;
	}

	.itemimg img {
		width: 7.5rem;
		height: 4.2rem;
	}

	.itemtitle {
		width: 7.5rem;
		height: 0.6rem;
		line-height: 0.6rem;
		font-size: 0.28rem;
		color: #333333;
		text-indent: 0.1rem;
		font-weight: bold;
		 white-space: nowrap; /* 不换行 */
		  overflow: hidden; /* 隐藏超出部分 */
		  text-overflow: ellipsis; /* 显示省略号 */
	}

	.iteminfo {
		width: 7.5rem;
		height: 0.4rem;
		line-height: 0.4rem;
		text-indent: 0.1rem;
		font-size: 0.26rem;
		color: #666666;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.itemtime {
		width: 7.5rem;
		height: 0.4rem;
		line-height: 0.4rem;
		text-indent: 0.1rem;
		font-size: 0.24rem;
		color: #666666;
	}

	.banner {
		width: 100%;
		margin-top: 0.2rem;
		height: 1.54rem;
	}

	.banner img {
		width: 15.4rem;
		height: 1.54rem;
	}

	.headwordboxNew {
		width: 3.1rem;
		height: 0.3rem;
		line-height: 0.3rem;
		color: #C0153E;
		text-align: center;
		font-size: 0.24rem;
		background-color: #FAFAFA;
		margin-top: 0.5rem;
		letter-spacing: 0.3rem;
		border-radius: 0.04rem;
		margin-left: 0.2rem;
		
	}

	.competition {
		background-color: #FFF1F2;
		width: 3rem;
		height: 1.2rem;
		border-radius: 0.1rem;
		margin-top: 0.4rem;
		overflow-x: auto;
		flex-shrink: 0;
		margin-left: 0.2rem;
	}

	.competitionover {
		background-color: #fff;
		width: 3rem;
		height: 1.2rem;
		border-radius: 0.1rem;
		margin-top: 0.4rem;
		overflow-x: auto;
		flex-shrink: 0;
		margin-left: 0.2rem;
	}


	.competitiontime {
		width: 0.66rem;
		border-radius: 0.04rem;
		margin-left: 0.04rem;
		height: 1rem;
		margin-top: 0.1rem;
		background-color: #C0153E;
		float: left;
		text-align: center;
		display: flex;
		flex-direction: column;
		color: #C0153E;
		
	}

	.competitiontimewill {
		
	}

	.competitiontitle {
		width: 2.0rem;
		margin-left: 0.1rem;
		line-height: 0.5rem;
		height: 0.5rem;
		float: left;
		/* text-align: center; */
		font-size: 0.24rem;
		color: #333333;
		font-weight: bold;
		 white-space: nowrap; /* 不换行 */
		  overflow: hidden; /* 隐藏超出部分 */
		  text-overflow: ellipsis; /* 显示省略号 */
	}



	.competitioninfo {
		width: 2.0rem;
		margin-left: 0.1rem;
		height: 0.7rem;
		float: left;
		font-size: 0.2rem;
		color: #666666;
	}

	.xuxian {
		width: 0.58rem;
		height: 9999rem;
		position: absolute;
		left: 0;
		top: 0;
		border-right: #333333 1px dashed;
		z-index: 1;
	}

	.xuxianshang {
		position: relative;
		z-index: 2;
	}

	.select-right {
		text-align: left !important;

	}

	.select_title {
		font-weight: 0 !important;
	}

	.select_item {
		font-weight: 0 !important;
	}
	.newwai{
		width: 100%;
	}
	@media screen and (max-width: 768px) {
		.newwai{
			width: 7.3rem;
			margin: 0 auto;
		}
		.leftbox {
			width: 100%;
			overflow: hidden;
			
		}

		.bodybox {
			width: 7.5rem;
			margin: 0 auto;
			overflow: hidden;
		}


		.changetime {
			width: 2.6rem;
			text-align: center;
			background-color: #C0153E;
			border-radius: 0.1rem;
			height: 0.5rem;
			line-height: 0.5rem;
			font-size: 0.28rem;
			color: #fff;
			font-weight: bold;
			float: right;
			margin-right: 0.2rem;
		}

		.changetime img {
			width: 0.3rem;
			height: 0.3rem;
			float: left;
			margin-top: 0.1rem;
			margin-left: 0.2rem;
		}

		.timeword {
			float: left;
			margin-left: 0.5rem;
		}

		.itembox {
			width: 7.5rem;
			overflow: hidden;
			margin-top: 0.2rem;
		}

		.item {
			width: 7.1rem;
			height: 5.4rem;
			float: left;
			background-color: #FAFAFA;
			margin-left: 0.2rem;
			margin-bottom: 0.3rem;

		}

		.itemimg {
			width: 7.1rem;
			height: 4rem;
		}

		.itemimg img {
			width: 7.1rem;
			height: 4rem;
		}

		.itemtitle {
			width: 7.1rem;
			height: 0.6rem;
			line-height: 0.6rem;
			font-size: 0.28rem;
			color: #333333;
			text-indent: 0.1rem;
		}

		.iteminfo {
			width: 7.1rem;
			height: 0.4rem;
			line-height: 0.4rem;
			text-indent: 0.1rem;
			font-size: 0.26rem;
			color: #666666;
		}

		.itemtime {
			width: 7.1rem;
			height: 0.4rem;
			line-height: 0.4rem;
			text-indent: 0.1rem;
			font-size: 0.24rem;
			color: #666666;
		}

		.banner {
			width: 7.5rem;
			height: 0.75rem;
			margin-top: 0.2rem;
		}

		.banner img {
			width: 7.5rem;
			height: 0.75rem;
		}

		.rightbox {
			display: none;
		}

		.rightboxs {
			width: 7.5rem;
			overflow: hidden;
			margin-top: 0.6rem;
			display: block;
		}

		.headwordbox {
			width: 3.1rem;
			height: 0.3rem;
			line-height: 0.3rem;
			color: #C0153E;
			text-align: center;
			font-size: 0.22rem;
			background-color: #FAFAFA;
			margin-top: 0.5rem;
			letter-spacing: 0.2rem;
			margin-left: 0.2rem;

		}

		.xuxianshang {
			position: relative;
			z-index: 2;
		}

		.competitionbox {
			display: flex;
			align-items: center;
			overflow-x: auto;
			transition: all 500ms linear;
		}
	}
</style>