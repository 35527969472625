import axios from "axios";
import qs from "qs";
import {
    getSystemLang
} from "../lang";
let domain  = window.location.protocol+"//"+window.location.host;
// axios.defaults.baseURL = '/test/api/index.php/';

if(window.location.hostname=="localhost"){
    axios.defaults.baseURL = '/swd/index.php/';
}else{
    axios.defaults.baseURL = domain+'/public/index.php/'; //正式
}
export default function($url,$param){
    let lang = getSystemLang();
    if(lang == 'cn')
    {
        lang = 'zh-cn';
    }
    else if(lang == 'en')
    {
        lang = 'en-us';
    }
    else
    {
        lang = 'zh-cn';
    }

    // let tparam = [];
    // if($param instanceof Object)
    // {
    //     for(let key in $param)
    //     {
    //         tparam.push(key+'='+ encodeURIComponent($param[key]));
    //     }
    // }

    // if(tparam.length > 0)
    // {

    //     $url = 'core/'+lang+'/'+$url+'?'+tparam.join("&"); 
    // }
    // else
    // {
    //     $url = 'core/'+lang+'/'+$url;
    // }

    $url = 'core/'+lang+'/'+$url;
    
    return axios.post($url,qs.stringify($param));
}