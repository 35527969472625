<template v-if="banner.length>0">
	<div class="one lazyLoad" v-if="type==0&&banner&&banner[0]" >
		<a  :href="banner[0].linkurl&&banner[0].linkurl!=''?banner[0].linkurl:'javascript:void()'">
			<img :src="banner[0].picurl" alt="" style="width:100%" @load="loadimage = true"/>
		</a>
	</div>
	<div class="one" v-if="type==1">
		<el-carousel class="swiper_banner"  :circular="true" :indicator-position="positions"  interval="10000">
			<el-carousel-item class="swiper_banner_item lazyLoad" v-for="item in banner" :key="item"  >
				<a  :href="item.linkurl&&item.linkurl!=''?item.linkurl:'javascript:void()'">
					<img :src="item.picurl" alt=""  @load="loadimage = true"/>
				</a>
			</el-carousel-item>
		</el-carousel>
	</div>
	<div class="one" v-if="type==2">
		<el-carousel class="swiper_banner"  :circular="true" direction="vertical"   :indicator-position="positions" interval="10000">
			<el-carousel-item class="swiper_banner_item lazyLoad" v-for="item in banner" :key="item"  >
				<a  :href="item.linkurl&&item.linkurl!=''?item.linkurl:'javascript:void()'">
					<img :src="item.picurl" :alt="item.picurl"  @load="loadimage = true"/>
				</a>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
	import axiosx from '../axios/postx';
	import axios from '../axios/request';
	export default {
		props: {
			positions: {
				type: String,
				default: 'none'
			},
			heightNew: {
				type: String
			},
			type: {
				type: Number,
				default: 0
			},
			abs:{
				type: String
			},
			datatype: {
				type: String,
				default: "1"
			},
			dataurl:{
				type: Array,
				default: []
			}
		},
		data() {
			return {
				classshow: false,
				banner:[],
				loadimage: false
				// appheight:100
				//oneClass:getApp().globalData.viewModel
			}
		},
		watch:{
			dataurl:function(newVal,oldVal){	
				this.banner = newVal;
			},
			loadimage:function(newVal,oldVal){
				if(newVal == true){
					this.$parent.returnmethod && this.$parent.returnmethod()
				}
			}
		},
		created() {
			this.banner = this.dataurl;
		},
		mounted() {
			// console.log("this.heightNew" + this.heightNew)
		}
	}
</script>
<style scoped>
	.one {
		width: 100%;
		height: 100%;
	}

	.one img {
		width: 100%;
		height: 100%;
	}

	.swiper_banner {
		width: 100%;
		height: 100%;
	}
	.swiper_banner .el-carousel__container{
		width: 100%;
		height: 99%!important;
		overflow: hidden;
	}
	.swiper_banner_item {
		width: 100%;
		height: 101%;
	}
	.swiper_banner_item a{
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}
	.swiper_banner_item a img{
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}
</style>