<template>
	<div class="lf_wai">
		<div  class="lf_Top_1">
		    <el-row v-show="showhome">
		        <el-col :xs="24" :sm="8" :md="8">
		            <div class="lf_left_1" :class="cj.info||cj.gj?'':'navleft'">
		                <div class="lf_left_1_1"  v-if="cj.info && isMobile()">
		                    <router-link target="_blank" v-if="cj.info" :to="{path:'/Matchnow',query:{id:cj.info.ID,type:'cj',bFlag:'lxb',lang:systemlang}}">
		                    {{langs.RealtimeScore}}
		                    </router-link>
		                </div>
                        <div v-else-if="cj.info" class="lf_left_1_1"  v-if="cj.info">
		                    <router-link target="_blank" v-if="cj.info" :to="{path:'/Matchnow',query:{id:cj.info.ID,type:'cj',bFlag:'zdcj',lang:systemlang}}">
		                    {{langs.RealtimeScore}}
		                    </router-link>
		                </div>
		                <div v-if="cj.gj" class="lf_left_1_2">
		                   <img v-if="cj.gj&&cj.gj.su_rank_code==''" :src="cj.gj.pl_country_img" alt="">
						   <!-- <div style="font-style: initial;">{{cj.gj&&cj.gj.pl_country?cj.gj.pl_country:''}}</div> -->
		                    <div v-if="cj.gj&&cj.gj.su_rank_code==''" style="font-weight: bold;">{{cj.gj&&cj.gj.pl_name?cj.gj.pl_name:''}}</div>
                            <div v-if="cj.gj&&cj.gj.su_rank_code=='T'" style="font-weight: bold;">LEADERS</div>

		                </div>
						<div v-if="cj.gj" class="lf_left_1_3">
							<div style="font-size: 0.28rem;font-weight: bold;">{{cj.gj&&cj.gj.su_to_par?cj.gj.su_to_par:''}}</div>
							<div style="font-size: 0.28rem;" v-if="cj.info"><span style="font-weight: bold;">{{cj.gj&&cj.gj.rank&&cj.gj.su_rank_code!='T'?cj.gj.rank+'':''}}</span>{{cj.gj&&cj.gj.su_rank_code=='T'?'':'st'}}</div>
						</div>
		            </div>
		        </el-col>
		        <el-col :xs="24" :sm="16" :md="16">
		            <div class="lf_right_1" v-show="hometopshow">
						<img v-if="!bannershow || bannershow['HOME_TOP'] != '1'" src="../assets/images/Home_top.png" alt="">
                        <div v-show="bannershow && bannershow['HOME_TOP'] == '1'">
                            <banner @EventBack='setbannershow' :type="0" abs="HOME_TOP"></banner>
                        </div>
		            </div>
		        </el-col>
		    </el-row>
		</div>
	</div>
    
</template>

<script>
	import 'element-plus/theme-chalk/display.css';
    import axios from '../axios/request';
    import banner from './banner';
    import {
        getSystemLang,
        getLangAll
    } from '../lang';
    export default {
        data() {
            return {
                langs: '',
                systemlang: 'cn',
                banner:{},
                cj:{},
                bannershow:[],
                hometopshow: false,
                showhome: false
            };
        },
        name: 'navs',
        props: {
            msg: String,

        },
        components:{
            banner
        },
        created() {
            this.systemlang = getSystemLang();
            this.langs = getLangAll(this.systemlang);

            //获取banner
            axios('news/banner/nav.json',{abs:'NAV_RIGHT'})
            .then((res)=>{
                let data = res.data.data;
                this.banner = data;
            });

            //获取及时成绩
            //获取banner
            axios('matchs/match/curmatch.json')
            .then((res)=>{
                let data = res.data.data;
                this.cj = data;
            });
        },
        methods:{
            setbannershow(abs, isshow) {
				console.log(abs,isshow,'ssssssssssssssss');
				this.bannershow[abs] = isshow;
                var width = document.body.clientWidth;
				if(width<=750 && this.bannershow["HOME_TOP"] == false){
                    this.hometopshow = false;
                }else{
                    this.hometopshow = true;
                }
                this.showhome = true;
			},
            isMobile(){
				const reg = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  				return reg.test(navigator.userAgent);
			},
        }

    }
</script>

<style scoped>
	.lf_wai{
		width: 100%;
		background-color: #fff;
	}
	.lf_Top_1{
		width: 100%;
		height: 1.54rem;
		margin: 0 auto;
	}
    .lf_left_1 {
        width: 100%;
        display: flex;
        align-items: center;
        height: 1.54rem;
		
        background-color: rgba(192, 21, 62, 1);
		
    }

    .lf_left_1_1 {
        width: 1.46rem;
        height: 0.5rem;
        background: inherit;
        background-color: rgba(215, 215, 215, 0);
        box-sizing: border-box;
        border-width: 0.02rem;
        border-style: solid;
        border-color: rgba(255, 255, 255, 1);
        border-radius: 2px;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        font-family: '微软雅黑';
        font-weight: 400;
        font-style: normal;
        font-size: 0.28rem;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left:0.2rem
        
    }

    .lf_left_1_2 {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 0.28rem;
		margin-left: 0.2rem;
		flex-grow: 1;
    }
	.lf_left_1_2 div {
	       margin-right: 0.14rem;
	   }
	   .lf_left_1_2 div:nth-last-of-type(1) {
			flex-grow: 1;
	      }
.lf_left_1_2 img {
        width: 0.34rem;
        margin-right: 0.1rem;
		box-shadow: 0 0 0.05rem rgba(0, 0, 0, 0.35);
    }
 
   
	.lf_left_1_3{
		display: flex;
		align-items: center;
		color: #fff;
		font-size: 0.28rem;
		width: 1.5rem;
		
	}
	.lf_left_1_3 div {
        margin-right: 0.14rem;
    }

    .lf_left_1_3 img {
        width: 0.3rem;
        height: 0.3rem;
        border-radius: 50%;
        margin-right: 0.05rem;
    }
    .lf_right_1 {
        width: 100%;
		height: 1.54rem;
    }
	.lf_right_1 img {
	    width: 100%;
		height: 1.54rem;
	}
	@media screen and (max-width: 768px) {
		.lf_Top_1{
			height: unset;
		}
		.lf_left_1{
			height: 0.9rem;
		}
		.lf_right_1 {
		    width: 100%;
			height: 0.88rem;
		}
		.lf_right_1 img {
		    width: 100%;
			height: 0.88rem;
		}
		.lf_left_1_1{
			font-size: 0.24rem;
		}
		.lf_left_1_2{
			font-size: 0.24rem;
		}
        .navleft{
            display:none;
        }
	}
</style>
