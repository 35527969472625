<template>
	<div class="lf_footer2">
		<div class="lf_footerbody">
			<el-row justify="center">
				<el-col :xs="24" :sm="16" :md="16">
					<div class="lf_footer2_tit_box">
						<div class="lf_foot_tit_text1 hoverUnderLine">
							<router-link  target="_blank" :to="{path:'/des',query:{lang:systemlang}}">
								{{langs.AboutCLPGA}}
							</router-link>
						</div>
						<!-- <div class="lf_foot_tit_text2">{{langs.CreatingValuescn}}</div>
						<div class="lf_foot_tit_text3">{{langs.CreatingValuesen}}</div> -->

					</div>
				</el-col>
			</el-row>
			<el-row type="flex" justify="center">
				<el-col :xs="24" :sm="16" :md="16">
					<div class="lf_info_div">
						<el-row>
							<el-col :xs="24" :sm="8" :md="8">
								<div class="lf_info_div_1">
									<div class="lf_info_div_1_1">
										{{langs.Beijing}}
									</div>
									<div class="lf_info_div_1_2">
										<div>{{langs.textTEL}}:+86 010-67156483</div>
									</div>
								</div>
							</el-col>
							<el-col :xs="24" :sm="8" :md="8">
								<div class="lf_info_div_1">
									<div class="lf_info_div_1_1">
										{{langs.Email}}
									</div>
									<div class="lf_info_div_1_2">
										<div>info@clpga.org&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
									</div>
								</div>
							</el-col>
							<el-col :xs="24" :sm="8" :md="8">
								
								<div class="lf_info_div_1">
									<div class="lf_info_div_1_1">
										{{langs.Marketing}}
									</div>
									<div class="lf_info_div_1_2">
										<div>xiangnan@clpga.org</div>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>

				</el-col>
			</el-row>
			<el-row>
				<el-col :xs="24" :sm="24" :md="24">
					<div class="lf_footer2_code_img_box">
						<img class="lf_footer2_code_img" src="/uploadfile/Links/202211/1667957506.png" alt="">
						<img class="lf_footer2_code_img" src="/uploadfile/Links/202211/1667957480.png" alt="">

					</div>
				</el-col>
			</el-row>
			<el-row>
				<el-col :xs="24" :sm="24" :md="24">
					<div class="lf_footer2_name">
						{{langs.CLPGABeijing}}
					</div>

				</el-col>
			</el-row>
		</div>
		<div class="lf_footer2_name_des">
			<div style="display: flex;align-items: center;justify-content: center;">
				 <a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch" style="display:flex;align-items: center; text-decoration:none;height:20px;line-height:20px;color: #fff;">
					 <img style="width: 20px;height: 20px;" src="../../src/assets/images/ga.png"/>
					 <p style="height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#fff;">京公网安备 11010102004131号</p >
				 </a>
			</div>
			<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" style="display:flex;align-items: center; text-decoration:none;height:20px;line-height:20px;color: #fff;">
				<span style="margin-left: 0.1rem;position: relative;top: -1px;">京ICP备13016187号</span>
			</a>
			
		</div>
	</div>

</template>

<script>
	import axios from '../axios/request';
	import axios1 from '../axios/postjs';
	import {
		wexinShare
	} from '@/assets/utils.js';
	import {
		getSystemLang,
		getLangAll
	} from "../lang";
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				banner: {},
				fenxiangcontent: {
					title: "CLPGA",
					desc: "为女子职业球员创造价值",
					imgUrl: "http://clpga.org/clpga.jpg"
				},
				match_id_name: ''

			};
		},
		name: 'Footer1',
		props: {
			msg: String,
			tFlag: {
				type: String,
				default: 'match'
			}
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			axios('news/banner.json', {
					abs: 'HOME_BOTTOM'
				})
				.then((res) => {
					let data = res.data.data;
					this.banner = data.HOME_BOTTOM;
				});
			var mt_id = window.location.href.split('#')[0].split("id=")[1];

			axios('matchs/match/matchtj.json', {
					otp: 'info',
					mt_id: mt_id
				})
				.then((res) => {
					let data = "";
					if(res.data.data.length>0){
						data = res.data.data.info.ColumnName;
					}
					this.match_id_name = data;
				});

		},
		mounted() {
			var that = this;
			setTimeout(function() {
				that.weixin()
			}, 1000)

		},
		methods: {
			weixin() {
				var that = this;
				//请求微信配置参数接口（获取签名），由后台给接口给
				var urls = window.location.href.split('#')[0];
				//看后台请求接口是get/post
				axios1('http://www.wifigolf.com/home/mobile/index.php/Home/Rank/wxsdk.html?url=' + encodeURIComponent(
					urls)).then(res => {

					if (res.status == 200) {
						//微信加签
						var obj = {
							appId: res.data.appId,
							nonceStr: res.data.nonceStr,
							signature: res.data.signature,
							timestamp: res.data.timestamp,
							jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
						};
						//分享数据，这段主要是为了在hash模式下分享出去的链接不被浏览器截取，保证完全把链接分享出去
						//var navLang = localStorage.getItem('lang')||'cn';
						var url = window.location.href;
						//url = url.split('?')[0];   
						//url = window.location.href+'?&lang='+navLang+'&type='+that.tFlag;
						console.log('url---', url);
						// var shareWxLink = encodeURIComponent(url);加密
						let shareData = {
							title: that.match_id_name || that.fenxiangcontent.title, // 分享标题
							desc: that.fenxiangcontent.desc,
							//link: res.data.linkUrl,
							link: url,
							imgUrl: that.fenxiangcontent.imgUrl // 分享图标
						};
						//引用
						wexinShare(obj, shareData);
					} else {
						alert('获取sdk参数失败！');
					}
				});
			},

		}


	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.lf_footer2 {
		width: 100%;
		background: url(../assets/images/black-s.png);
		margin: 0.7rem auto 0;
	}

	.lf_footerbody {

		margin: 0 auto;

	}

	.lf_footer2_tit_box {
		color: #fff;
	}

	.lf_foot_tit_text1 {
		font-size: 0.26rem;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 0.30rem;
	}

	.lf_foot_tit_text2 {
		font-size: 0.26rem;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 0.14rem;
		text-align: center;
	}

	.lf_foot_tit_text3 {
		font-size: 0.26rem;
		display: flex;
		align-items: center;

		text-align: center;
	}

	.lf_info_div {
		padding: 0.22rem 0;
		margin: 0.10rem auto;

		border: 1px solid rgba(239, 239, 239, 0.9);
	}

	.lf_info_div_1 {
		color: #fff;
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.lf_info_div_1_1 {
		margin-right: 0.2rem;
		font-size: 0.24rem;
		line-height: 0.32rem;
		display: flex;
		justify-content: flex-end;
		width: 1.3rem;
	}

	.lf_info_div_1_2 {
		font-size: 0.24rem;
	}

	.lf_info_div_1_2 div {
		line-height: 0.32rem;
	}

	.lf_footer2_code_img_box {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		margin-top: 0.30rem;

	}

	.lf_footer2_code_img {
		width: 0.94rem;
		height: 0.94rem;
		margin: 0 0.1rem;
	}

	.lf_footer2_name {
		color: #fff;
		font-size: 0.24rem;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 0.30rem;
		margin-bottom: 0.4rem;
	}

	.lf_footer2_name_des {
		color: #fff;
		font-size: 0.24rem;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 0.10rem;
		padding-bottom: 0.10rem;

		border-top: #fff 1px solid;
	}

	.lf_footer2_tit_box .lf_foot_tit_text1 {
		display: flex;
	}

	.lf_footer2_tit_box div {
		display: block;
	}

	@media screen and (max-width: 768px) {
		.lf_info_div_1_1 {
			width: 2.4rem;
		}

		.lf_footer2_tit_box .lf_foot_tit_text1 {
			padding: 0 0.3rem;
			display: flex;
		}
		.lf_match_info_box{
			margin-bottom: 0.2rem;
			width:7.02rem;
			margin:0 auto;
		}
		.d_title_r{
			margin-right: 0;
		}
		.lf_footer2_tit_box div {
			padding: 0 0.3rem;
			display: block;
			font-size: 0.24rem;
		}

		.lf_footer2_tit_box div span {
			font-weight: 700;
		}

		.lf_info_div_1_2 {
			width: 3.2rem;
		}

		.lf_info_div {
			border: unset;
		}

		.lf_info_div div div div {
			font-size: 0.24rem;
		}

		.lf_footer2_name {
			font-size: 0.24rem;
		}

	}
</style>