import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import vueCookies from 'vue-cookies'
import VueLazyload from 'vue-lazyload'

import BaiduMap from 'vue-baidu-map-3x';

createApp(App)  
    .use(router)
    .use(ElementPlus)
    .use(vueCookies)
	.use(BaiduMap, {
  // ak: 'CGLw0FVGAA08GLm2PPSmECaqSQUu5rG3'
  ak:'okXG7KXOZpER0dp2G7nh9bub1S3hGu3m'
}).use(VueLazyload, {
  // preLoad: 1.3,
  error: '/images/CLPGA.gif',//图片错误的替换图片路径(可以使用变量存储)
  loading: '/images/CLPGA.gif',//正在加载的图片路径(可以使用变量存储)
  // attempt: 1
})
    .mount('#app')